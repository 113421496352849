import IKDataEntity from '@/plugins/EntityFrameWork/IKDataEntity'
import { hillo } from 'hillo'

const entity = {
  orderId: {
    displayName: 'orderNumber'
  },
  date: {},
  progressivoInvio: {
    displayName: 'bills'
  },
  pivaPaese: {
    displayName: 'Country'
  },
  piva: {
    displayName: 'taxNumber'
  },
  denominazione: {
    displayName: 'CompanyName'
  },
  cap: {
    displayName: 'postCode'
  },
  status: {
    displayName: 'Status'
  },
  sendEmail: {
    type: IKDataEntity.Types.Integer,
    form: false,
    tableConfig: {
      overwrite: true
    }
  }
}

const config = {
  load: async function (filter) {
    return (await hillo.get('FatturaPAController.php?op=getFPAList', {})).content.map(it => {
      it.status = it.pecSend.toString() === '0' ? 'Non Inviato' : it.pecSend.toString() === '1' ? 'Inviato' : 'Invio Fallito'
      return it
    }).sort((a, b) => b.date.localeCompare(a.date))
  }
}

export default IKDataEntity.ModelFactory(entity, config)

// export async function getBillInfoByNumber (pivaPease, piva) {
//   return await hillo.get('https://ec.europa.eu/taxation_customs/vies/rest-api/ms/' + pivaPease + '/vat/' + piva, {})
// }

export async function getBillInfoByNumber (nation, code) {
  return (await hillo.get('FatturaPAController.php?op=checkTaxNumber', {
    nation,
    code
  })).content
}
